import React, { FC } from "react"
import { render } from 'datocms-structured-text-to-plain-text'
import SEO from "~/utils/SEO"
import Footer from "~/components/molecules/Footer/Footer"
import Hero from "~/components/molecules/Hero/Hero"
import Modal from "~/components/atoms/Modal/Modal"
import SideMenu from "../SideMenu/SideMenu"
import Link from "~/components/atoms/Link/Link"
import * as css from './ApartmentPage.module.scss'
import { Apartment } from "../../atoms/Block/components/ApartmentsList/ApartmentsList"

const ApartmentPage: FC<any> = ({ id, ...apartment }) => {
  console.log(apartment)

  const slug = `apartments/${id}`
  const seo = {
    title: apartment.name,
    description: render(apartment.shortDescription),
  }
  const heading = apartment.name;
  const subheading = '';
  const image = apartment.images[0].fluid.src;

  return (
    <>
      <main>
        <div className={`apartment-page ${id}`}>
          <SEO
            {...{
              title: seo?.title || heading,
              description: seo?.description || subheading || "",
              slug,
              image,
            }}
          />
          <Hero {...{ heading, subheading }} />
          <div className="page-content">
            <div className={`${css.wrapper} ${css.bar}`}>
              <div className={css.inner}>
                <Link className={css.backLink} leftArrow to="/apartments">View all apartments</Link>
              </div>
            </div>
            <div className={css.wrapper}>
              <div className={css.inner}>
                <Apartment {...apartment} id={id} expanded />
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <SideMenu />
      <Modal />
    </>
  )
}

export default ApartmentPage
